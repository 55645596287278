*::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 40px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

*::-webkit-scrollbar-thumb {
  background-color: #e7ebf1;
  border-radius: 40px;
}
